<template>
  <div class="pingan">
    <div class="main" id="main" ref="main">
      <img src="https://lvdowebui.oss-cn-qingdao.aliyuncs.com/upload/app/FWZS/file/d027af37-c1eb-462d-b645-d5b2c670da73/t8uwg0c98lsk8fac86e814af32d5cea67196670e1af0.png" alt="" @load="loadImg">
      <div class="btn" @click="downLoad" >立即下载</div>
    </div>
    <div class="wx" v-if="showwx">
      <img src="../assets/wx.png" alt="">
    </div>
    <!-- <div class="banner">
      <img src="../assets/download_img_bg380.png" alt="">
      <div class="down">
        <div @click="downLoad">立即下载</div>
      </div>
    </div>
    <img class="des" src="../assets/download_img_particulars.png" alt="">
    <div class="wx" v-if="showwx">
      <img src="../assets/wx.png" alt="">
    </div> -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showwx:false
      }
    },
    mounted() {

      
    },
    methods: {
      loadImg(){
        var h=this.$refs.main.offsetHeight
          console.log(h)
          document.getElementById('main').style.marginTop=-h/2+'px'
      },
      downLoad() {
        // var u = navigator.userAgent;
        // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        // if (isAndroid) {
        //   window.location = "http://dl.ilvdo.com/download/akh";
        // } else {
        //   if(this.isWeiXin()){
        //     this.showwx=true
        //   }else{
        //     window.location.href = "https://itunes.apple.com/cn/app/id992714103?mt=8";
        //   }
        // }
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          var loadDateTime = new Date();
          window.location = 'pingan-client://?action=pingan'
          window.setTimeout(function () { //如果没有安装app,便会执行setTimeout跳转下载页
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
              window.location = "http://dl.ilvdo.com/download/akh";
            } else {
              window.close();
            }
          }, 500);
        } else {
          window.location = 'https://mob.ilvdo.com/Mobile/download.html?to=pingan'
        }
      },
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
    }
  }

</script>

<style scoped lang="scss">
  .pingan{
    background: #FFCEBE;
    position: absolute;
    width: 100%;
    height: 100%;
    .main{
      position: absolute;
      top: 50%;
      img{
        width: 100%;
        display: block;
        margin-bottom: 56px;
      }
      .btn{
        width: 533px;
        height: 93px;
        background: #FFFFFF;
        border-radius: 70px;
        text-align: center;
        line-height: 93px;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FF6A41;
        margin: 0 auto;
      }
    }
  }
  .wx{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    text-align: right;
    img{
      width: 600px;
    }
  }
  .des {
    width: 100%;
  }

</style>
<style>


</style>
